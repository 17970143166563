import React from 'react'
import { Link, useStaticQuery } from 'gatsby'
import MothButtons from '../../components/MothButtons'
import SEO from '../../components/seo'
import "../layout.css"
import BackgroundImage from 'gatsby-background-image'


export default function Writing() {

    // const data = useStaticQuery(
    //     graphql`
    //       query {
    //         desktop: file(relativePath: { eq: "octo-words.png" }) {
    //           childImageSharp {
    //             fluid(maxWidth: 2000) {
    //               ...GatsbyImageSharpFluid
    //             }
    //           }
    //         }
    //       }
    //     `
    //   )
    
    //   // Set ImageData.
    //   const imageData = data.desktop.childImageSharp.fluid
    
    //   return (
    //           <div className="writing-content-wrap">
    //         <MothButtons size="small" order={[
    //                 {to: "/home", label:  "Home"},
    //                 {to: "/portfolio", label:  "Portfolio"}
    //                 ]}/>
    //     <BackgroundImage
    //       Tag="section"
    //       className="artctopus"
    //       fluid={imageData}
    //     >
    //     <div className="artctopus-ball" id="assemblage-ball">
    //         <Link to="assemblage" />
    //     </div>
    //     <div className="artctopus-ball" id="collage-ball">
    //         <Link to="collage" />
    //     </div>
    //     <div className="artctopus-ball" id="cards-ball">
    //         <Link to="cards"/>
    //     </div>
    //     <div className="artctopus-ball" id="illustration-ball">
    //         <Link to="illustration" />
    //     </div>  
    //     </BackgroundImage>
    //     </div>
    //   )

    return (
        <>
        <SEO title="Other Art" />
        <div className="writing-content-wrap">
            <MothButtons size="small" order={[
                    {to: "/home", label:  "Home"},
                    {to: "/portfolio", label:  "Portfolio"}
                    ]}/>
            <div className="artctopus">
                <div className="artctopus-ball" id="assemblage-ball">
                    <Link to="assemblage" />
                </div>
                <div className="artctopus-ball" id="collage-ball">
                    <Link to="collage" />
                </div>
                <div className="artctopus-ball" id="cards-ball">
                    <Link to="cards"/>
                </div>
                <div className="artctopus-ball" id="illustration-ball">
                    <Link to="illustration" />
                </div>  
            </div>
            <div className="preloaded-gd-images" id="artctopusBallRo" />
        </div>
        </>
    )
}
